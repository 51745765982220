import { FORM_VALUE_INTER } from './constant';

export const convertUser = (data: any) => {
  if (!data) {
    return;
  }

  const converedData = data?.list.map((e: any) => {
    const convert = {
      label: e.userName,
      userName: e.userName,
      level: e.level,
      referalUser: e.referalUser,
      value: e.id,
    };

    return convert;
  });

  return { list: converedData };
};

export const convertEvent = (data: any) => {
  if (!data) {
    return;
  }

  const converedData = data?.list.map((e: any) => {
    const convert = {
      label: e.title,
      value: e.id,
    };

    return convert;
  });

  return { list: converedData };
};

export const convertCreateData = (payload: FORM_VALUE_INTER) => ({
  userId: String(payload?.user[0].value),
  eventId: payload?.event[0].value,
  comment: payload?.comment,
  amount: payload?.amount,

  isPerson: payload?.isPerson,

  isInvited: payload?.isInvited,

  isFortuna: payload?.isFortuna,

  personData: payload?.personData,
  ...(payload?.isPerson && { personAmount: payload?.personAmount }),
  ...(payload?.isInvited && { invitedAmount: payload?.invitedAmount }),
  ...(payload?.isFortuna && { fortunaAmount: payload?.fortunaAmount }),
});
